import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src170988513/src/solar-energy-partners/src/templates/page.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Hero = makeShortcode("Hero");
const HeroImage = makeShortcode("HeroImage");
const HeroContent = makeShortcode("HeroContent");
const Container = makeShortcode("Container");
const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const Button = makeShortcode("Button");
const SplitSection = makeShortcode("SplitSection");
const Section = makeShortcode("Section");
const BackgroundText = makeShortcode("BackgroundText");
const ContactForm = makeShortcode("ContactForm");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Hero mdxType="Hero">
      <HeroImage overlayOpacity={0.5} mdxType="HeroImage">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1920px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "66.66666666666666%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAQBAwX/xAAWAQEBAQAAAAAAAAAAAAAAAAAAAQL/2gAMAwEAAhADEAAAAYsUzs10QD//xAAYEAEBAQEBAAAAAAAAAAAAAAACAQMAEv/aAAgBAQABBQI6nZLWGTVdMfHVpK9//8QAFREBAQAAAAAAAAAAAAAAAAAAABH/2gAIAQMBAT8BV//EABURAQEAAAAAAAAAAAAAAAAAAAAR/9oACAECAQE/AYj/xAAdEAACAAcBAAAAAAAAAAAAAAAAAQIDEBESITFh/9oACAEBAAY/Am+bHLtY2zJRemT7T//EABkQAQEBAQEBAAAAAAAAAAAAAAERACExcf/aAAgBAQABPyGVKeDd2A+2Y5AfusqJ4nMoWrNu/9oADAMBAAIAAwAAABAEP//EABYRAQEBAAAAAAAAAAAAAAAAAAEAEf/aAAgBAwEBPxBUMtF//8QAFhEBAQEAAAAAAAAAAAAAAAAAAQAR/9oACAECAQE/EALpYv/EABwQAQACAgMBAAAAAAAAAAAAAAEAETFBIVGBkf/aAAgBAQABPxBaEUXL75Dsl0FzdvzEXIR4RzUY1xQ7WBoe4oHMZtdbn//Z')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/700a157b66bcc7ecbbdfea595f789925/d3be9/long-term-career-CPR54697.webp 480w", "/static/700a157b66bcc7ecbbdfea595f789925/e46b2/long-term-career-CPR54697.webp 960w", "/static/700a157b66bcc7ecbbdfea595f789925/882b9/long-term-career-CPR54697.webp 1920w"],
                "sizes": "(max-width: 1920px) 100vw, 1920px",
                "type": "image/webp"
              }}></source>{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/700a157b66bcc7ecbbdfea595f789925/7cc5e/long-term-career-CPR54697.jpg 480w", "/static/700a157b66bcc7ecbbdfea595f789925/6a068/long-term-career-CPR54697.jpg 960w", "/static/700a157b66bcc7ecbbdfea595f789925/0f98f/long-term-career-CPR54697.jpg 1920w"],
                "sizes": "(max-width: 1920px) 100vw, 1920px",
                "type": "image/jpeg"
              }}></source>{`
          `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/700a157b66bcc7ecbbdfea595f789925/0f98f/long-term-career-CPR54697.jpg",
                "alt": "Solar Energy Lifestlye Careers",
                "title": "Solar Energy Lifestlye Careers",
                "loading": "lazy",
                "decoding": "async",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                }
              }}></img>{`
        `}</picture>{`
    `}</span></p>
      </HeroImage><HeroContent mdxType="HeroContent">
        <Container mdxType="Container">
          <Row mdxType="Row"><Column sm={60} mdxType="Column">
              <h1>{`Investment & Education`}</h1>
              <p style={{
                fontSize: '1.2rem'
              }}>Opportunities like this don't come around everyday.</p>
              <Button as='a' href='#inquire' mdxType="Button">Start Your Career</Button>
            </Column></Row>
        </Container>
      </HeroContent></Hero>
    <SplitSection img='investment-1X6A0037' title='Investment' position='left' mdxType="SplitSection">
      <p>{`Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt.`}</p>
      <Button as='a' href='#inquire' mdxType="Button">Get Started</Button>
    </SplitSection><SplitSection img='investment-education' title='Education' theme='dark' background='#ffffff' mdxType="SplitSection">
      <p>{`Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.`}</p>
      <p>{`Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur?`}</p>
      <Button as='a' href='#inquire' mdxType="Button">Get Started</Button>
    </SplitSection><Section id='inquire' mdxType="Section">
      <Container style={{
        position: 'relative',
        marginTop: 100
      }} mdxType="Container">
        <h2 style={{
          position: 'relative',
          zIndex: 1
        }}>Get Started Today</h2>
        <BackgroundText content='Inquire' style={{
          top: -130
        }} mdxType="BackgroundText" />
        <ContactForm mdxType="ContactForm" />
      </Container>
    </Section>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      